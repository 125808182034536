.is-muted
  color: #d6d6d6

.is-uppercase
  text-transform: uppercase

.is-bold
  font-weight: 700 !important

.is-black
  color: #313131 !important

.is-success
  color: #52c41a

.is-danger
  color: #c5141d

.is-disabled
  color: #cecece

.ant-input-search .ant-input-group-addon
  left: -1px
  padding: 0
  border: 0

.ant-form-actions-wrapper
  margin-top: 24px
  display: flex
  justify-content: right

.ant-page-header-heading-title
  line-height: 40px

.gem-descriptions
  .ant-descriptions-item
    padding-bottom: 0 !important
    .ant-descriptions-item-label
      font-weight: 600
    .ant-descriptions-item-content
      padding-bottom: 10px

.ant-page-header
  padding-top: 0 !important


.w-full
  width: 100%

.w-70
  width: 70%

.flex
  display: flex

.align-items-end
  align-items: flex-end

.align-items-center
  align-items: center

.align-items-baseline 
  align-items: baseline

.break-spaces
  white-space: break-spaces

.ml-auto
  margin-left: auto

.is-italic
  font-style: italic

.is-underline
  text-decoration: underline

.tabs-card
  &.ant-tabs-card 
    .ant-tabs-content > .ant-tabs-tabpane
      padding: 16px
      background: #FFF
      border: 1px solid #f0f0f0
      border-top: 0
    .ant-tabs-nav
      margin-bottom: 0
      &::before
        display: none

.qm-ant-modal-footer
  display: flex
  .ant-form-actions-wrapper
    margin-left: auto

.ant-page-header-heading-sub-title
  align-self: flex-end

.ant-tag
  border: 0
  text-transform: uppercase
  font-weight: normal
  &.ant-tag-blue
    color: #FFF
    background-color: #395ba6
  &.ant-tag-geekblue
    background-color: #08819b
    color: #FFF
  &.ant-tag-red
    background-color: #CD7F7F
    color: #FFF
  &.ant-tag-green
    color: #FFF
    background-color: #2EB3A9
  &.ant-tag-ghost
    background-color: transparent !important
    &.ant-tag-black
      color: #313131
      border: 1px solid #313131

.search-filter-button
  display: flex
  justify-content: end

.vertical-divider
  display: inline-block
  margin: 0 10px
  width: 2px
  height: 16px
  background: #333

.ant-popover-no-padding
  .ant-popover-inner-content
    padding: 0

.outlined-btn
  background-color: #F2F5F5
  color: #F79622
  font-weight: bold
  border: solid 1px
  padding: 0px 20px 0px 20px
  &.white-bg
    background-color: white
  &:hover, &:focus
    background-color: #F79622
    color: #fff
    border: solid 1px
    border-color: #F79622

.filled-btn 
  color: #fff
  background-color: #F79622
  border-color: #F79622
  font-weight: bold
  padding: 0px 20px 0px 20px
  &:hover, &:focus
    background-color: darken(#F79622, 12)
    color: #fff
    border: solid 1px
    border-color: #F79622

.rounded-btn
  border-radius: 60px

.ant-collapse-header
    padding: 0 !important

.ant-segmented
  background-color: white
  &-item
    color: black
    &:hover
      color: black
  &-item-selected
    color: white
    &:hover
      background-color: darken(#2eb3a9, 5%)
      color: white

.flex
  display: flex

.align-items-end
  align-items: flex-end

.align-items-center
  align-items: center

.justify-center
  justify-content: center

.font-medium
  font-weight: 500

.hover-primary-color
  &:hover
    color: var(--primary-color)
    > svg path
      fill: var(--primary-color)

@import "modules/modal"
@import "modules/alert"
@import "modules/table"